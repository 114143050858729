import React, { useState, useRef, useEffect } from "react";
import dance2 from "./imgs/scan_instruction/front_position.webm";
import demo2 from "./imgs/scan_instruction/FINALFR.mp4";
import front_pose_img from "./imgs/scan_instruction/front_position.png";
import Background from "./Background";
import { ring, pinwheel, grid } from "ldrs";
import orientation from "./imgs/scan_instruction/orientation.png";
import Header from './Header';
import wreath from "./imgs/review_icons/wreath.png";
import { useNavigate } from 'react-router-dom';
import Intercom from '@intercom/messenger-js-sdk';
import SpinningText from "./SpinngText";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import bgImage from './imgs/tree_blurred.png';

import { useUser } from './UserManage';




import Cookies from "js-cookie";


ring.register();
pinwheel.register();
grid.register();

const ScanMobile = ({ setLocalScan }) => {

  const { fingerprint, scanList, loading, error } = useUser();

  const navigate = useNavigate();

  Intercom({
    app_id: 'euaigswj',
  });


  const [intercomVisible, setIntercomVisible] = useState(true); // Control Intercom visibility

  useEffect(() => {
    console.log("Intercom visibility changed:", intercomVisible);
    const intercomElements = [
      document.querySelector("#intercom-frame"),
      document.querySelector(".intercom-lightweight-app"),
      document.querySelector("#intercom-container"),
      document.querySelector("#intercom-css-container"),
    ];

    intercomElements.forEach((el) => {
      if (el) {
        if (intercomVisible) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      }
    });
  }, [intercomVisible]); // Re-run effect when intercomVisible changes


  const isMobile = /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [cam_disabled, setCamDisabled] = useState(false);
  const [connecting_camera, setConnectingCamera] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [processingScan, setProcessingScan] = useState(false);
  const [height, setHeight] = useState(0);
  const [selectedHeight, setSelectedHeight] = useState(false);
  const [units, setUnits] = useState("inches");
  const [finalUUID, setFinalUUID] = useState("");

  let t_speedup = 800;


  const [ratios, setRatios] = useState({})

  const updateRatios = (e) => {
    const multiplier = parseFloat(e.target.value); // Convert the input value to a number

    if (isNaN(multiplier)) return; // Guard against invalid input

    const multiplyValues = (obj, multiplier) => {
      const newObj = { ...obj }; // Create a shallow copy of the object
      Object.keys(newObj).forEach(key => {
        if (typeof newObj[key] === 'number') { // Ensure the value is a number
          newObj[key] *= multiplier;
        }
      });
      return newObj;
    };

    console.log(e.target.value);
    setRatios(multiplyValues(aiResults, multiplier));
  };



  const videoRef = useRef(null);

  const handleEnableCamera = async () => {
    setConnectingCamera(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraEnabled(true);
      setCamDisabled(false);
      setConnectingCamera(false);

      // Set the video stream to the 4video element
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      console.log("Camera access granted:", stream);
      setIntercomVisible(false); // Hide Intercom when camera is enabled

    } catch (error) {
      console.error("Error accessing camera:", error);
      setCamDisabled(true);
      setConnectingCamera(false);
    }
  };

  const checkOrientation = () => {
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  useEffect(() => {
    // Check orientation on mount and on window resize
    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  const [countdown, setCountdown] = useState(0);
  const instructionalPoseRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedImageSecondary, setCapturedImageSecondary] = useState(null);

  let firstImg = null;
  let secondImg = null;


  const [countdownSecondary, setCountdownSecondary] = useState(0);



  const [turn60, setTurn60] = useState(false);
  const [coverTextTop, setCoverTextTop] = useState("HOLD FIELD");
  const [coverTextBottom, setCoverTextBottom] = useState("GOAL POSE");
  let takingSide = false;

  const [doingSidePhoto, setDoingSidePhoto] = useState(false);

  const captureResizedImage = (video) => {
    // Step 1: Capture the image at original resolution
    const originalCanvas = document.createElement("canvas");
    const originalContext = originalCanvas.getContext("2d");
    originalCanvas.width = video.videoWidth;
    originalCanvas.height = video.videoHeight;
    originalContext.drawImage(video, 0, 0, originalCanvas.width, originalCanvas.height);

    // Step 2: Downsize the image to 720p while maintaining aspect ratio
    const maxWidth = 1280;
    const maxHeight = 720;
    let width = originalCanvas.width;
    let height = originalCanvas.height;

    // Calculate new dimensions while preserving aspect ratio
    if (width > height) {
      if (width > maxWidth) {
        height = (height * maxWidth) / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = (width * maxHeight) / height;
        height = maxHeight;
      }
    }

    const resizedCanvas = document.createElement("canvas");
    const resizedContext = resizedCanvas.getContext("2d");
    resizedCanvas.width = width;
    resizedCanvas.height = height;
    resizedContext.drawImage(originalCanvas, 0, 0, width, height);

    return resizedCanvas.toDataURL("image/png");
  };


  const handleTakeFrontPhoto = () => {
    setCapturedImage(null)
    setCountdown(12);
    setCoverTextTop("HOLD FIELD");
    setCoverTextBottom("GOAL POSE");

    const interval = setInterval(() => {
      setCountdown((prev) => {
        console.log(prev); // Log the current value of countdown

        if (prev <= 9 && prev >= 7 && turn60 === false) {
          setTurn60(true)

        } else {
          setTurn60(false)
        }

        if (prev <= 1) {
          console.log("Say cheese!");

          // Capture the photo from the video feed
          if (videoRef.current) {
            const canvas = document.createElement("canvas");
            const video = videoRef.current;

            // Set canvas dimensions to match the video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current frame from the video onto the canvas
            const context = canvas.getContext("2d");
            if (context) {


              // Convert canvas to image data URL
              const photoDataUrl = captureResizedImage(video);

              // Save the captured image to state
              setCapturedImage(photoDataUrl);
              console.log("first", photoDataUrl)
              firstImg = photoDataUrl;
              setCountdown(0);
              console.log(takingSide)
              if (prev === 1 && !takingSide) {

                takingSide = true;
                handleTakeSidePhoto();
                return;
              }

            }
          }

          clearInterval(interval);
          return prev; // Return the current value to prevent changes
        } else {
          return prev - 1;
        }
      });
    }, t_speedup);
  };


  const handleTakeSidePhoto = () => {
    console.log("WHADDUP")
    setCapturedImageSecondary(null)
    setCountdownSecondary(11);
    setCoverTextTop("TURN");
    setCoverTextBottom("45 DEGREES");

    const interval = setInterval(() => {
      setCountdownSecondary((prev) => {
        console.log(prev > 7, prev, turn60 === false)
        if (prev > 7 && turn60 === false) {
          setTurn60(true)

        } else {
          setTurn60(false)
          setDoingSidePhoto(true) //! jerry rigged to hide the captured title
        }
        console.log(prev); // Log the current value of countdown
        if (prev <= 1) {
          console.log("Say cheese!");

          // Capture the photo from the video feed
          if (videoRef.current) {
            const canvas = document.createElement("canvas");
            const video = videoRef.current;

            // Set canvas dimensions to match the video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current frame from the video onto the canvas
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Convert canvas to image data URL
              const photoDataUrl = captureResizedImage(video);

              // Save the captured image to state
              setCapturedImageSecondary(photoDataUrl);
              secondImg = photoDataUrl;
              setCountdownSecondary(0);
              console.log("secondary", photoDataUrl)
              setProcessingScan(true);
              setTurn60(true)
              getResults()

            }
          }


          clearInterval(interval);
          return prev; // Return the current value to prevent changes
        } else {
          return prev - 1;
        }
      });
    }, t_speedup);
  }

  const [aiResults, setAiResults] = useState("");

  let commenceResults = false;
  const getResults = async () => {
    if (!commenceResults) {
      setTurn60(true);
      commenceResults = true;
    }
    else { return; }
    setCoverTextTop("Nice job!");
    setCoverTextBottom("Processing...");


    console.log("TURN  60 TRUE AND COVER TEXT SET WTF")

    // Ensure images are captured before processing
    console.log("capturedImage, capturedImageSecondary")
    console.log(firstImg, secondImg)
    if (!firstImg || !secondImg) {
      console.error("Images not captured properly.");
      return;
    }

    try {

      // Prepare the payload for the API request
      const payload = {
        fingerprint, // Attach the fingerprint ID
        frontImage: firstImg,
        sideImage: secondImg
      };


      // Make the API request
      const response = await fetch("/analyze", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      setFinalUUID(result.id);

      // Retrieve existing cookie data
      const existingData = Cookies.get("uuidList");
      let uuidList = existingData ? JSON.parse(existingData) : {};

      // Add new UUID to the list
      uuidList[result.id] = "You"; // Modify the name as needed

      // Save back to cookies
      Cookies.set("uuidList", JSON.stringify(uuidList), { expires: 7 });


      //! WHEN THE RESULT PAGE IS FLESHED OUT REDIRECT HERE INSTEAD
      // navigate(`/${result.id}`);
      navigate('/people')


      // const result = await response.json();

      // console.log("Body Scan Results:", result);

      // const measurementsJsonString = result.data


      // const validJsonString = measurementsJsonString.replace(/'/g, '"');

      // setLocalScan(validJsonString)


      // const jsonObject = JSON.parse(validJsonString);
      // console.log(jsonObject);

      // //! Upload to the backend after AI results are ready
      // try {
      //   const uploadResponse = await fetch("/upload", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(jsonObject),
      //   });

      //   if (!uploadResponse.ok) {
      //     throw new Error('Failed to upload data to backend');
      //   }

      //   const uploadResult = await uploadResponse.json();
      //   console.log('Uploaded Successfully, ID:', uploadResult.id);
      //   setFinalUUID(uploadResult.id);

      //   if (selectedHeight) {
      //     navigate(`/${finalUUID}`);
      //   }

      // } catch (error) {
      //   console.error('Error uploading to backend:', error);
      // }

      // setAiResults(jsonObject);
      // console.log("NAVIGATING TO BODY...")

      // // Process and display the results
      // setCoverTextTop("Nice job!");
      // setCoverTextBottom("Getting results.");

      // // Handle results (e.g., display or store them)
      // // Example: You can set the results to state for rendering
      // console.log(result.data);
    } catch (error) {
      navigate('/example');
      console.error("Error during body scan:", error);
      setCoverTextTop("Error");
      setCoverTextBottom("Try Again");
    } finally {
      setIntercomVisible(true); // Show Intercom after processing
    }
  };



  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const handleLoadedMetadata = (videoRef) => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Set start time to 3 seconds
    }
  };

  const handleTimeUpdate = (videoRef) => {
    if (videoRef.current) {
      // If video reaches the end, reset it to the 3-second mark
      if (videoRef.current.currentTime >= videoRef.current.duration) {
        videoRef.current.currentTime = 3;
        videoRef.current.play();
      }
    }
  };

  let wreath_rotate = "0deg";

  let bg_video_rotate = "160deg";


  const selectHeightFunc = () => {
    console.log("WE ARE CONFIRMING")
    if (height === 0) {
      return;
    }

    setSelectedHeight(true);
    if (finalUUID) {

    }
  }

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    const originalThemeColor = metaThemeColor ? metaThemeColor.getAttribute("content") : null;

    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#FFFFFF"); // Set theme color to white
    }

    return () => {
      if (metaThemeColor && originalThemeColor) {
        metaThemeColor.setAttribute("content", originalThemeColor); // Restore the original theme color
      }
    };
  }, []);




  return (
    <div className="w-full min-h-screen overflow-x-hidden">
      <div className={`relative w-full h-screen flex items-center justify-center`}>
        <div className="fixed w-full h-full flex justify-center items-center z-4 bg-[#FFFFFF] hue-rotate-[0deg] bg-opacity-0">
          <div className="bg-[#FFFFFF] w-full h-[100px] absolute top-[-50px]"></div>
          <video
            ref={videoRef1}
            id="videoElement1"
            src={`${demo2}`}
            autoPlay
            muted
            playsInline

            className={`absolute w-auto max-h-screen object-contain`}
            onLoadedMetadata={() => handleLoadedMetadata(videoRef1)}
            onTimeUpdate={() => handleTimeUpdate(videoRef1)}
          />

        </div>
        <div className={`z-[3] md:ml-[60px] lg:ml-[30px] ${isMobile ? "mt-[-40px]" : "mt-[-30px]"}`}>
          <div className=" w-fit md:text-6xl text-3xl font-helixa-regular leading-4">
            Let's create your
          </div>
          <div className="w-fit mt-[0px] md:mt-[10px] md:text-9xl text-[60px] font-helixa-black ">
            sizeform
          </div>
          {/* <div className={`md:text-lg mt-2 md:mt-6 font-helixa-regular text-black/70 ${!connecting_camera ? "" : "hidden"}`}>
            We built a perfect 3D model of you—now, online shopping finally fits.
          </div> */}

          {!cameraEnabled ? (
            <div>
              <div className="flex space-x-4 mt-10 md:mt-16 text-xl ">
                <button
                  onClick={handleEnableCamera}
                  className={`relative ${!isMobile ? "btn-slide-show" : "h-[20px] w-[350px]"} px-[150px] md:px-[240px] py-[30px] ${connecting_camera ? "hidden" : ""
                    }`}
                >
                  <p className={`btn-slide-show-text1 mt-[-25px] ${isMobile ? "hidden" : ""}`}>Let's go!</p>
                  <div className="btn-slide-show-inner rounded-full">
                    <p className="btn-slide-show-text2">Start Now →</p>
                  </div>
                </button>

                <div className={`${connecting_camera ? "" : "hidden"}`}>

                  <l-grid
                    size="80"
                    stroke="5"
                    bg-opacity="0"
                    speed="2"
                    color="black"
                  ></l-grid>
                </div>

              </div>

            </div>
          ) : (
            <div
              className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-slate z-[1000] bg-white bg-cover bg-center"
              style={{ backgroundImage: `url()` }}
            >

              <div className="relative max-w-[750px] w-[100%]">
                <div className="text-black text-center text-3xl mt-[20px] mb-[20px]">
                  {capturedImage && countdown <= 1 && (!doingSidePhoto) ? <>Captured.</> : <></>}
                  {!capturedImage && countdown <= 0 ? <>Get body into frame</> : <></>}

                  {capturedImageSecondary && doingSidePhoto ? <>Captured.</> : <></>}
                  {!capturedImageSecondary && doingSidePhoto ? <>Keep field goal pose</> : <></>}

                  {countdown > 0 ? <>Get body into frame</> : <></>}
                </div>
                <div className="relative w-auto">
                  <div className="relative w-full">

                    <video
                      id="cameraFeed"
                      ref={videoRef}
                      className="w-full h-auto object-contain scale-x-[-1]"
                      autoPlay
                      playsInline
                      muted
                    />

                  </div>

                  {capturedImage && !doingSidePhoto ? (

                    <img
                      src={capturedImage}
                      alt="Captured pose"
                      className="absolute inset-0 w-[100%] mx-auto grayscale scale-x-[-1]"
                    />

                  ) : <></>}
                  {capturedImageSecondary && doingSidePhoto ? (

                    <img
                      src={capturedImageSecondary}
                      alt="Captured pose"
                      className="absolute inset-0 w-[100%] mx-auto grayscale scale-x-[-1]"
                    />

                  ) : <></>}
                  <div className="absolute inset-0 bg-[#ffffe6] opacity-100 pointer-events-none mix-blend-multiply"></div>
                  <div className={`absolute inset-0 text-white text-6xl font-helixa-regular flex flex-col justify-center ${countdown > 0 || countdownSecondary > 0 ? "hidden" : ""}`}><div className="text-center hidden">Place phone<br />for body scan</div></div>
                  <div className={`absolute inset-0 text-white text-6xl font-helixa-regular flex flex-col justify-center ${!countdownSecondary > 0 ? "hidden" : ""}`} ><div className="text-center hidden">{coverTextTop}<br />{coverTextBottom}</div></div>
                  <div className={`z-50 font-helixa-black text-white absolute top-0 inset-0 text-9xl text-center flex flex-col justify-center ${countdown === 0 ? "hidden" : ""}`}><>{countdown}</></div>
                  <div className={`z-50 font-helixa-black text-white absolute top-0 inset-0 text-9xl text-center flex flex-col justify-center ${countdownSecondary === 0 || !doingSidePhoto ? "hidden" : ""}`}><>{countdownSecondary}</></div>


                </div>
                <div className={`fixed top-0 left-0 inset-0 bg-white text-black text-6xl flex flex-col 
                  justify-center z-[999] text-center ${turn60 ? "h-full" : "h-[0px]"}`} style={{ transition: "height 1s ease" }}><div className={`${turn60 ? "opacity-100" : "opacity-0"}`} style={{ transition: "opacity 1s ease" }}>
                    {!processingScan ? <div> {coverTextTop}<br />{coverTextBottom} </div> : <div>


                      {!selectedHeight && aiResults === "" ? (
                        <div>
                          <div className="text-black font-helixa-regular mb-5 text-3xl">
                            Generating sizeform
                          </div>
                          <l-grid
                            size="80"
                            stroke="5"
                            bg-opacity="0"
                            speed="2"
                            color="black"
                          ></l-grid>
                        </div>
                      ) : !selectedHeight ? (
                        <div>
                          <div className="text-black font-helixa-regular mb-5 text-3xl">
                            Enter Your Height
                          </div>
                          <div className="flex flex-col items-center space-y-4">
                            <input
                              type="number"
                              min="0"
                              placeholder="Enter height"
                              value={height}
                              onChange={(e) => setHeight(parseFloat(e.target.value))}
                              className="w-40 border-2 border-black rounded-lg p-2 text-center"
                            />
                            <div className="flex space-x-4">
                              <button
                                onClick={() => setUnits("inches")}
                                className={`px-4 py-2 rounded-full border ${units === "inches" ? "bg-black text-white" : "bg-white text-black"
                                  }`}
                              >
                                Inches
                              </button>
                              <button
                                onClick={() => setUnits("cm")}
                                className={`px-4 py-2 rounded-full border ${units === "cm" ? "bg-black text-white" : "bg-white text-black"
                                  }`}
                              >
                                Centimeters
                              </button>
                            </div>
                            <button
                              onClick={selectHeightFunc}
                              className="px-6 py-2 bg-black text-white rounded-lg mt-4"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      ) : null}


                    </div>}

                  </div></div>





                <div className={`flex space-x-4 text-2xl justify-center h-[60px]`}>
                  <button
                    onClick={() => { handleTakeFrontPhoto(); }}
                    className={`relative flex justify-center w-full px-5 py-2 text-white  ${countdown > 0 || countdownSecondary > 0 ? "hidden" : ""}`}
                  >
                    <div className={`${!capturedImage ? "bg-black text-white" : "bg-white text-white"} w-[100%]  text-center h-full flex-col justify-center flex rounded-full`}>
                      {countdown > 0 || countdownSecondary > 0 ? <div className="flex justify-center mt-10"></div> : "Ready"}  →
                    </div>
                  </button>

                  {countdown > 0 || countdownSecondary > 0 ? <div className="mt-2"> <l-grid
                    size="60"
                    stroke="5"
                    bg-opacity="0"
                    speed="2"
                    color="black"
                  ></l-grid> </div> : <></>}

                </div>


                {/* <div className="absolute top-4 left-4 text-2xl text-center font-bold font-helixa-regular">
                  Camera connected.
                </div> */}

              </div>

            </div>
          )}

          <div
            className={`text-center w-full text-2xl mt-10 text-red-600/80 ${!cam_disabled ? "hidden" : ""
              }`}
          >
            Camera is <b>disabled</b>.
          </div>
        </div>
        {/* <div className="fixed w-full h-full flex justify-center items-center z-[-3]">
          <video
            ref={videoRef1}
            id="videoElement1"
            src={`${demo2}`}
            autoPlay
            muted
            playsInline

            className={`absolute w-auto max-h-screen object-contain hue-rotate-[30deg]`}
            onLoadedMetadata={() => handleLoadedMetadata(videoRef1)}
            onTimeUpdate={() => handleTimeUpdate(videoRef1)}
          />

        </div> */}
      </div>

      {/* Scrollable content below full page section */}
      <div className="w-full bg-gray-100/0 z-4 flex flex-col justify-end mt-[-200px] relative hidden">

        <div className="opacity-100 text-center  flex flex-row flex-wrap justify-center py-[50px] text-4xl font-helixa-regular text-black/70">
          <div className=" flex flex-row justify-center p-3">
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] scale-x-[-1] brightness-0`} />
            <div className="md:text-lg my-auto px-3 lg:px-5 text-[16px] leading-6">Never guessing again.<br /><span className="font-helixa-black"> Maxime</span>, Paris, France</div>
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] brightness-0`} />
          </div>

          <div className=" flex flex-row justify-center p-3">
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] scale-x-[-1] brightness-0`} />
            <div className="md:text-lg my-auto px-3 lg:px-5 text-[16px] leading-6">★ ★ ★ ★ ★<br /><span className="font-helixa-black"> Francis</span>, Petaluma, CA</div>
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] brightness-0`} />
          </div>
          <div className=" flex flex-row justify-center p-3">
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] scale-x-[-1] brightness-0`} />
            <div className="md:text-lg my-auto px-3 lg:px-5 text-[16px] leading-6">Mandatory by fashion law.<br /><span className="font-helixa-black"> Claudia</span>, San Francisco, CA</div>
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] brightness-0`} />
          </div>
          <div className=" flex flex-row justify-center p-3">
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] scale-x-[-1] brightness-0`} />
            <div className="md:text-lg my-auto px-3 lg:px-5 text-[16px] leading-6">This is my new superpower.<br /><span className="font-helixa-black"> Kara</span>, Austin, Texas</div>
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] brightness-0`} />
          </div>
          <div className=" flex flex-row justify-center p-3">
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] scale-x-[-1] brightness-0`} />
            <div className="md:text-lg my-auto px-3 lg:px-5 text-[16px] leading-6">Body empowering.<br /><span className="font-helixa-black"> Marissa</span>, Chicago, IL</div>
            <img src={wreath} alt="wreath" className={`w-[60px] sm:w-[70px] brightness-0`} />
          </div>

        </div>
        {/* <div className="h-fit w-full flex flex-col bg-gradient-to-b from-red-400/60 to-pink-300/60 py-12 md:py-20">
          <div className="max-w-[1500px] mx-auto">
            <div className="text-[45px] md:text-6xl text-white/80 font-helixa-black pl-[20px] md:px-[50px]">Shop with <span className="text-red-400/100">science</span></div>
            <div className="text-[20px] md:text-2xl text-white/90 font-helixa-regular mt-10  px-[20px] md:px-[50px] text-left leading-relaxed max-w-5xl">


              At University of California, Berkeley, a physicist asked this question while working on techniques to image molecular structure:<br />
              <span className="italic text-white bg-red-400/40 inline-block px-3 py-3 rounded-lg my-10">
                &ldquo;If we can image molecules to see how they fit into proteins, why can't we image people to see how they fit into clothes?&rdquo;

              </span>
              <span classNfame=" text-white bg-red-400/40 inline-block px-3 py-3 rounded-lg my-4">
                So we decided to use science to ensure your clothes fit before you buy.
              </span>
            </div>
          </div>
        </div> */}
        <div className="w-full text-center font-helixa-regular px-5 text-white/80 bg-slate-900">

          <div className=" text-xl mt-5 ">
            Need support?</div>

          <div className="text-xl font-helixa-regular mb-5">info@sizeform.com</div>
          {/* <div className="font-helixa-regular text-xl mb-5">We want your feedback. 1-2 day response time.</div> */}


        </div>
        <div className="w-full text-center text-white bg-slate-950 py-2">
          © Sizeform LLC, 2025. All rights reserved.
        </div>
      </div>
      <div className="w-full h-full">
        {/* <div className={`fixed top-0 left-0 w-full z-[999]  ${isMobile ? " h-[55px] backdrop-blur-sm" : ""}  ${cameraEnabled ? "hidden" : ""}`}>
          <Header /></div> */}
        {isLandscape && isMobile && (
          <div className="fixed top-0 left-0 w-full z-[999] h-full flex items-center justify-center bg-black  text-white text-center flex-col">
            <img src={orientation} alt="Rotate device to portrait mode" className="invert w-[200px] mb-10" />
            <div className="text-3xl font-bold font-helixa-regular">

              {/* Use <span className="font-helixa-black">portrait mode</span> */}

              <div className="flex flex-row justify-center">
                <div>
                  <l-pinwheel
                    size="35"
                    stroke="3.5"
                    speed="0.9"
                    color="white"
                  ></l-pinwheel>
                </div>
                <div className="pl-4">
                  Rotate device...</div>

              </div></div>
          </div>
        )}
        <div className="fixed w-full h-full z-50 flex flex-col justify-center">

        </div>

      </div >

    </div >
  );
};

export default ScanMobile;
