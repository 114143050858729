import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Scan from './Scan';
import ScanMobile from './ScanMobile';
import ScanR from './ScanR';
import Partners from './Partners';
import LinkPhone from './LinkPhone';
import Body from './Body';
import TestQuery from './TestQuery';
import People from './People';
import People2 from './People2';
import SendSizeformData from "./SendSizeformData";
import BodyData from './BodyData';
import Invite from './Invite';
import UserManage from './UserManage';

import { useState } from 'react';

function AppRouter() {

  const [localScan, setLocalScan] = useState("");

  return (
    <Router>
      <UserManage redirectOnSizeform={true} redirectPath="/people">
        <Routes>
          <Route path="/" element={<ScanMobile setLocalScan={setLocalScan} />} />
          <Route path="/scanMobile" element={<ScanMobile />} />
          <Route path='/people' element={<People />} />
          <Route path='/people2' element={<People2 />} />
          <Route path="/request" element={<SendSizeformData />} />
          <Route path="/link" element={<LinkPhone />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/scan" element={<Scan />} />
          <Route path='/test' element={<TestQuery />} />
          <Route path="/about" element={<Partners />} />
          <Route path="/:uuid" element={<Body />} />
          <Route path="/:uuid/data" element={<BodyData />} />
        </Routes>
      </UserManage>
    </Router>
  );
}

export default AppRouter;