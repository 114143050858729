import React, { useState } from "react";

const ShareButtonFree = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleShareClick = async () => {
    const inviteLink = window.location.href;

    try {
      // Attempt to use modern Clipboard API
      await navigator.clipboard.writeText(inviteLink);
      console.log("Link copied using Clipboard API");

      // Show success message
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.warn("Clipboard API failed, trying fallback method");

      // Fallback for Safari
      const textArea = document.createElement("textarea");
      textArea.value = inviteLink;
      textArea.style.position = "fixed";  // Prevents scrolling to bottom
      textArea.style.opacity = "0";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        console.log("Link copied using document.execCommand()");
      } catch (err) {
        console.error("Failed to copy using document.execCommand()", err);
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <button
      className="flex items-center gap-2 px-24 py-3 bg-white text-black rounded-lg shadow-lg transition-transform hover:scale-105 active:scale-95"
      onClick={handleShareClick}
    >
      {copySuccess ? 'Copied!' : '↑ Copy Share Link'}
    </button>
  );
};

export default ShareButtonFree;
