import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GrainBackdrop from "./grain/grain_backdrop";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ShareButton from "./ShareButton";

const Body = ({ localScan }) => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [sizeformData, setSizeformData] = useState(null); // Initialized as null to detect loading state
  const [isFavorite, setIsFavorite] = useState(false);
  const [fingerprint, setFingerprint] = useState("");
  const [loading, setLoading] = useState(true); // New state to handle loading
  const scrollContainerRef = useRef(null);

  const [confirmingRemove, setConfirmingRemove] = useState(false); // State for remove confirmation

  const inputRef = useRef(null);


  const [isOwner, setIsOwner] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");




  useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const userFingerprint = result.visitorId;
        setFingerprint(userFingerprint);

        const response = await fetch(`/api/get-sizeform-data?fingerprint=${userFingerprint}`);
        const data = await response.json();

        if (data.success && data.sizeforms[uuid]) {
          const sizeform = data.sizeforms[uuid];
          setSizeformData(sizeform);
          setIsFavorite(sizeform.isFavorite || false);

          // Check if the user owns this sizeform
          setIsOwner(sizeform.ownership === true);

          // Prepopulate newName state with the current name
          setNewName(sizeform.name || "Unnamed Sizeform");
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error("Error fetching Sizeform data:", error);
        navigate('/');
      } finally {
        setLoading(false); // Data is fully loaded
      }
    };
    fetchData();
  }, [uuid, navigate]);


  const handleUpdateName = async () => {
    try {
      const response = await fetch('/api/update-sizeform-name', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fingerprint,
          sizeformId: uuid,
          newName
        })
      });

      const result = await response.json();
      if (result.success) {
        setSizeformData(prev => ({ ...prev, name: newName }));
        setIsEditingName(false);
      } else {
        console.error("Failed to update sizeform name:", result.error);
      }
    } catch (error) {
      console.error("Error updating sizeform name:", error);
    }
  };


  const handleToggleFavorite = async () => {
    const newFavoriteState = !isFavorite;

    // Optimistically update UI
    setIsFavorite(newFavoriteState);

    try {
      const response = await fetch('/api/update-sizeform-favorite', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fingerprint,
          sizeformId: uuid,
          isFavorite: newFavoriteState,
        })
      });

      const result = await response.json();
      if (!result.success) {
        console.error("Failed to update favorite status:", result.error);
        setIsFavorite(!newFavoriteState); // Revert state if the request fails
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
      setIsFavorite(!newFavoriteState); // Revert state if the request fails
    }
  };


  const handleRemove = async () => {
    try {
      const response = await fetch('/api/remove-sizeform', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fingerprint, sizeformId: uuid })
      });

      const result = await response.json();
      if (result.success) {
        navigate('/people');
      } else {
        console.error("Failed to remove sizeform");
      }
    } catch (error) {
      console.error("Error removing sizeform:", error);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
  }

  return (
    <div className="w-full relative overflow-hidden" style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
      <GrainBackdrop />

      <div className="fixed top-0 left-0 w-full z-20 bg-transparent cursor-pointer">
        <div onClick={() => { navigate('/people') }} className="text-2xl text-left text-white/50 font-helixa-black pt-5 pb-5 pl-7">
          {"← sizeform"}
        </div>
      </div>

      <ShareButton />

      <div ref={scrollContainerRef} className="w-full h-full z-10 overflow-y-auto fade-mask pt-[100px] pb-[20px]" style={{ WebkitOverflowScrolling: 'touch' }}>
        <div className="px-7">
          <div className="flex mt-12 relative mx-auto max-w-[400px] text-5xl font-helixa-regular text-white/100 mb-5">


            {!isOwner && (
              <button className={`flex items-center justify-center ${isFavorite ? "w-12" : ""} h-12 hover:scale-105 transition-transform`}>
                {isFavorite ? (
                  <svg width="40" height="40" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                  </svg>
                ) : (
                  <></>
                )}
              </button>
            )}

            {/* Name Editing Logic */}
            {isOwner ? (
              <>
                {isEditingName ? (
                  <input
                    ref={inputRef}
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={handleUpdateName} // Automatically save on blur
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdateName();
                      }
                    }}
                    className="bg-white text-black p-1 rounded w-auto min-w-[150px] border border-gray-300"
                    autoFocus // Automatically focus on the input field
                  />
                ) : (
                  <h1
                    className="cursor-pointer font-helixa-black"
                    onClick={() => {
                      setIsEditingName(true);
                      setTimeout(() => inputRef.current?.focus(), 0); // Focus the input field when editing starts
                    }}
                  >
                    {sizeformData?.name || "Unnamed Sizeform"}
                  </h1>
                )}
              </>
            ) : (
              <h1 className="font-helixa-black">{sizeformData?.name || "Unnamed Sizeform"}</h1>
            )}

          </div>


          <div className="mx-auto max-w-[400px] text-white/100 mb-5">
            <div className="text-xl  text-white/70 mb-5 font-bold font-helixa-regular">#{uuid.substring(0, 8)}</div>

            {!isOwner && (
              <button onClick={handleToggleFavorite} className={`flex w-full justify-center items-center bg-white px-10 py-3 rounded-full text-black mt-10 hover:scale-105 transition-transform`}>
                {isFavorite ? (
                  <span className="ml-2">Unfavorite {sizeformData?.name || "this sizeform"}</span>
                ) : (


                  <span className="ml-2">Favorite {sizeformData?.name || "this sizeform"}</span>

                )}
              </button>
            )}

            {!confirmingRemove ? (
              <button
                onClick={() => setConfirmingRemove(true)}
                className="flex w-full justify-center items-center bg-black px-10 py-3 rounded-full text-red-400 mt-10 hover:scale-105 transition-transform"
              >
                <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 6h18M9 6v12m6-12v12M4 6l1 14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2l1-14M10 6V4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2" />
                </svg>
                <span className="ml-2">Remove {sizeformData?.name || "this sizeform"}</span>
              </button>
            ) : (
              <div className="mt-10 flex  items-start space-x-5">
                <button
                  onClick={handleRemove}
                  className="flex text-red-400 bg-black px-10 py-3 rounded-full hover:scale-105 transition-transform"
                >
                  <svg className="mr-1" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 6h18M9 6v12m6-12v12M4 6l1 14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2l1-14M10 6V4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2" />
                  </svg>
                  Confirm
                </button>
                <button
                  onClick={() => setConfirmingRemove(false)}
                  className="text-black bg-white px-10 py-3 rounded-full hover:scale-105 transition-transform"
                >
                  Cancel
                </button>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
