import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const BodyData = ({ localScan }) => {
  const { uuid } = useParams();

  const [usingExampleData, setUsingExampleData] = useState(false);

  const exampleData =
    '{"height":1,"head circumference":0.30911556718314287,"neck circumference":0.2715619178500639,"shoulder to crotch height":0.4198445956731115,"chest circumference":0.7269213842152149,"waist circumference":0.6961502715405412,"hip circumference":0.6786471870310405,"wrist right circumference":0.1151227927437757,"bicep right circumference":0.24553863846810253,"forearm right circumference":0.19402743987792304,"arm right length":0.3075345211206481,"inside leg height":0.4182200236013955,"thigh left circumference":0.379630848370739,"calf left circumference":0.2441328534665363,"ankle left circumference":0.15252064156380893,"right foot length":0.15583494844971776,"right foot width":0.05887686483152388,"shoulder breadth":0.3319684101849099,"back to shoulder":0.1480965733354208}';

  const [parsedData, setParsedData] = useState({});
  const [keyToTitle, setKeyToTitle] = useState({});
  const [ratioMap, setRatioMap] = useState({});

  // Fetch data based on UUID or use example data
  useEffect(() => {
    const fetchData = async () => {
      if (uuid) {
        try {
          const response = await fetch(`/sizeform/${uuid}`);
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const result = await response.json();
          setParsedData(result.data);
          setUsingExampleData(false);
        } catch (error) {
          console.error(error);
          setParsedData(JSON.parse(exampleData));
          setUsingExampleData(true);
        }
      } else {
        setParsedData(JSON.parse(exampleData));
        setUsingExampleData(true);
      }
    };

    fetchData();

    setKeyToTitle({
      height: "Height",
      "head circumference": "Head Circumference",
      "neck circumference": "Neck Circumference",
      "shoulder to crotch height": "Shoulder to Crotch",
      "chest circumference": "Chest Circumference",
      "waist circumference": "Waist Circumference",
      "hip circumference": "Hip Circumference",
      "wrist right circumference": "Wrist Circumference",
      "bicep right circumference": "Bicep Circumference",
      "forearm right circumference": "Forearm Circumference",
      "arm right length": "Arm Length",
      "inside leg height": "Inside Leg Height",
      "thigh left circumference": "Thigh Circumference",
      "calf left circumference": "Calf Circumference",
      "ankle left circumference": "Ankle Circumference",
      "right foot length": "Foot Length",
      "right foot width": "Foot Width",
      "shoulder breadth": "Shoulder Breadth",
      "back to shoulder": "Back to Shoulder",
    });

  }, [uuid]);

  // Calculate ratios once data is set
  useEffect(() => {
    if (Object.keys(parsedData).length > 0) {
      const ratioMapGeneration = {
        "Chest to waist": parsedData["chest circumference"] / parsedData["waist circumference"],
        "Chest to hips": parsedData["chest circumference"] / parsedData["hip circumference"],
      };
      setRatioMap(ratioMapGeneration);
    }
  }, [parsedData]);

  return (
    <div className="p-4">
      {usingExampleData && (<h1 className="text-3xl mb-5">Using example data</h1>)}

      <h3 className="text-lg font-bold mb-2">Key Ratios</h3>
      <table className="border-collapse border border-gray-400 w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-400 p-2">Ratio</th>
            <th className="border border-gray-400 p-2">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(ratioMap).map(([key, value]) => (
            <tr key={key}>
              <td className="border border-gray-400 p-2">{key}</td>
              <td className="border border-gray-400 p-2">{value.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />
      <h3 className="text-lg font-bold mb-2">Measurements</h3>
      <table className="border-collapse border border-gray-400 w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-400 p-2">Measurement</th>
            <th className="border border-gray-400 p-2">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(parsedData).map(([key, value]) => (
            <tr key={key}>
              <td className="border border-gray-400 p-2">{keyToTitle[key] || key}</td>
              <td className="border border-gray-400 p-2">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BodyData;
