import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Create a context
const UserContext = createContext();

// Custom hook to access UserContext
export const useUser = () => useContext(UserContext);

const UserManage = ({ children, redirectOnSizeform = false, redirectPath = '/people' }) => {
  const [fingerprint, setFingerprint] = useState(null);
  const [scanList, setScanList] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation(); // ✅ Getting the current path

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        // Retrieve fingerprint (generate if not in localStorage)
        let storedFingerprint = localStorage.getItem('fingerprint');
        if (!storedFingerprint) {
          const fp = await FingerprintJS.load();
          const result = await fp.get();
          storedFingerprint = result.visitorId;
          localStorage.setItem('fingerprint', storedFingerprint);
        }

        setFingerprint(storedFingerprint);

        // Fetch associated sizeforms using fingerprint
        const response = await fetch(`/api/get-sizeform-data?fingerprint=${storedFingerprint}`);
        const data = await response.json();

        if (data.success) {
          setScanList(data.sizeforms);

          const hasScans = Object.keys(data.sizeforms).length > 0;

          // ✅ Redirect to /people if scans exist and not already on /people
          if (hasScans && redirectOnSizeform && location.pathname === '/') {
            navigate(redirectPath);
          }

          // ✅ Redirect to / ONLY if user is at /people and has NO scans
          if (!hasScans && location.pathname === '/people') {
            navigate('/');
          }

          // ✅ Allow access to other routes without redirection
        } else {
          setScanList({});
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate, redirectOnSizeform, redirectPath, location.pathname]);

  return (
    <UserContext.Provider value={{ fingerprint, scanList, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserManage;
