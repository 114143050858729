import React, { useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const ShareButton = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShareClick = async () => {
    setLoading(true);
    try {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const userFingerprint = result.visitorId;

      const response = await fetch(`/api/get-sizeform-data?fingerprint=${userFingerprint}`);
      const data = await response.json();

      if (data.success) {
        const ownedSizeformEntry = Object.entries(data.sizeforms).find(([key, value]) => value.ownership === true);

        if (ownedSizeformEntry) {
          const [uuid, sizeformData] = ownedSizeformEntry;
          const ownedName = sizeformData.name || "Unnamed Sizeform";

          const inviteResponse = await fetch('/api/create-invite', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              fingerprint: userFingerprint,
              sizeformId: uuid,
              name: ownedName
            })
          });

          const result = await inviteResponse.json();
          if (result.success) {
            const inviteLink = `${window.location.origin}/invite?code=${result.invite_id}`;
            window.location.href = inviteLink;
          } else {
            console.error("Failed to create invite:", result.error);
          }
        } else {
          console.error("No owned sizeform found.");
        }
      } else {
        console.error("Failed to fetch sizeforms.");
      }
    } catch (error) {
      console.error("Error fetching fingerprint or sizeform data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed bottom-5 w-full flex justify-center z-30">
      <button
        className={`flex items-center gap-2 px-12 py-3 bg-white text-black rounded-full shadow-lg transition-transform ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 active:scale-95'}`}
        onClick={handleShareClick}
        disabled={loading}
      >
        {loading ? 'Loading...' : '↑ Share your sizeform'}
      </button>
    </div>
  );
};

export default ShareButton;
