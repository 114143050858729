import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import GrainBackdrop from "./grain/grain_backdrop";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import moment from "moment"; // Make sure moment is installed
import { QRCodeCanvas } from "qrcode.react";
import Header from "./Header";
import ShareButtonFree from "./ShareButtonFree";




const Invite = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [inviteData, setInviteData] = useState(null);
  const [fingerprint, setFingerprint] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alreadyHasOwnership, setAlreadyHasOwnership] = useState(false);

  useEffect(() => {
    const fetchFingerprint = async () => {
      console.log("⏳ Loading FingerprintJS...");
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      console.log("✅ Fingerprint successfully loaded:", result.visitorId);
      setFingerprint(result.visitorId);
    };
    fetchFingerprint();
  }, []);

  useEffect(() => {
    const fetchInvite = async () => {
      const code = searchParams.get("code");
      console.log("🧐 Invite Code from URL:", code);

      if (!fingerprint) {
        console.warn("🚨 Fingerprint not yet loaded, skipping invite check.");
        return;
      }

      try {
        console.log(`🌐 Fetching invite data for code: ${code}...`);
        const response = await fetch(`/api/invite/${code}`);
        const data = await response.json();
        console.log("📥 Invite Data Received:", data);

        if (data.success) {
          const currentTime = moment();
          const deadline = moment(data.invite.deadline);
          console.log("⏳ Current Time:", currentTime.toISOString());
          console.log("🕒 Invite Deadline:", deadline.toISOString());

          if (currentTime.isAfter(deadline)) {
            setError("This invitation has expired.");
            return;
          }

          setInviteData(data.invite);
          console.log("✅ Invite Data Set:", data.invite);

          console.log("🔍 Checking ownership status for fingerprint:", fingerprint);
          const ownershipCheckResponse = await fetch(`/api/get-sizeform-data?fingerprint=${fingerprint}`);
          const ownershipCheckData = await ownershipCheckResponse.json();
          console.log("📥 Ownership Data Received:", ownershipCheckData);

          if (ownershipCheckData.success) {
            const { sizeform_uuid } = data.invite;  // The ID we want to check

            console.log("📌 Checking for matching sizeform_uuid in user's data:", sizeform_uuid);

            const matchingSizeform = Object.values(ownershipCheckData.sizeforms).find(form => form.id === sizeform_uuid);

            if (matchingSizeform) {
              console.log("✅ Sizeform match found:", matchingSizeform);

              if (matchingSizeform.ownership === true) {
                console.warn("🛑 User already owns this sizeform. Marking as their invite.");
                setAlreadyHasOwnership(true);
              } else {
                console.log("⚠️ Sizeform exists, but ownership is false. Showing accept button.");
                setAlreadyHasOwnership(false);
              }
            } else {
              console.log("❌ No matching sizeform found in user's data.");
              setAlreadyHasOwnership(false);
            }
          }
        } else {
          console.error("❌ Failed to fetch invite data.");
          setError("This invitation is invalid or no longer available.");
        }
      } catch (error) {
        console.error("🔥 Error fetching invite:", error);
        setError("An error occurred while fetching the invite.");
      } finally {
        console.log("✅ Invite fetch process completed.");
        setLoading(false);
      }
    };

    if (fingerprint) {
      fetchInvite();
    }
  }, [searchParams, fingerprint]);

  const handleAccept = async () => {
    if (alreadyHasOwnership) return;

    try {
      const response = await fetch('/api/accept-invite', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fingerprint, inviteId: inviteData.invite_id })
      });

      const result = await response.json();
      console.log("📥 Accept Invite Response:", result);

      if (result.success) {
        console.log("✅ Invite successfully accepted. Redirecting to /people.");
        navigate('/people');
      } else {
        console.error("❌ Failed to accept the invitation.");
        setError("Failed to accept the invitation.");
      }
    } catch (error) {
      console.error("🔥 Error accepting invite:", error);
      setError("An error occurred while accepting the invite.");
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-white">{error}</div>;
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-center text-white">
      <GrainBackdrop />
      <div className="fixed top-0 left-0 w-full z-20 bg-transparent cursor-pointer">
        <div onClick={() => { navigate('/people') }} className="text-2xl text-left text-white/50 font-helixa-black pt-5 pb-5 pl-7">
          {"← sizeform"}
        </div>
      </div>
      <div className="mb-5 mt-[150px] max-w-[400px] w-full mx-auto pl-8">
        {alreadyHasOwnership ?
          <div className="leading-3"><div className="text-4xl font-helixa-black">{inviteData.name},</div><br /><div className="text-2xl font-helixa-regular">
            Share your sizeform</div></div> :
          <div className="leading-3"><div className="text-4xl font-helixa-black">Add {inviteData.name}</div><br /><div className="text-2xl font-helixa-regular">
            to your people</div></div>
        }
      </div>

      {alreadyHasOwnership && (
        <div className="flex flex-col items-left mt-5 max-w-[400px] w-full pl-8">
          <div className="w-full mb-5">
            <ShareButtonFree />
            <p className="text-white text-2xl mt-12 font-helixa-regular text-left">Or scan below:</p>
          </div>



          <QRCodeCanvas className="rounded-xl"
            value={window.location.href}
            size={196}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="H"
            includeMargin={true}
          />

        </div>
      )}


      {!alreadyHasOwnership && (
        <div className="pl-8 max-w-[400px] w-full mx-auto">
          <div className="flex space-x-4 mt-5">
            <button onClick={handleAccept} className="bg-black px-10 py-2 rounded-full text-white">Add Sizeform</button>
            <button onClick={() => navigate('/people')} className="bg-white px-10 py-2 rounded-full text-black">Cancel</button>
          </div></div>
      )}
    </div>
  );

};

export default Invite;
