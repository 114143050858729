import React, { useEffect } from 'react';
import grained from './grained';
import treeBackground from '../imgs/blurred_sky.jpg';

const GrainBackdrop = () => {
  const gradients = [
    // { color: '#17B169', position: '40% 30%' },
    // { color: '#018749', position: '20% 10%' },
    // { color: '#1CAC78', position: '60% 75%' },
    // { color: '#004953', position: '90% 5%' },
    // { color: '#00BFFF', position: '70% 60%' },
    // { color: '#2a52be', position: '10% 80%' },
  ];

  const speed = 8;

  useEffect(() => {
    grained('#grain-layer', {
      animate: true,
      patternWidth: 100,
      patternHeight: 100,
      grainOpacity: 0.05,
      grainDensity: 1,
      grainWidth: 1,
      grainHeight: 1,
      grainChaos: 0.5,
      grainSpeed: 20
    });

    const element = document.getElementById('grain-layer');
    gradients.forEach(({ color }, index) => {
      element.style.setProperty(`--color-${index}`, color);
    });
  }, [gradients]);

  const gradientStyle = gradients
    .map((stop, index) =>
      `radial-gradient(at ${stop.position}, var(--color-${index}) 0px, transparent 50%)`
    )
    .join(',\n');

  return (
    <div className="grain-backdrop-container">
      <style>
        {`
          ${gradients.map((stop, index) => `
            @property --color-${index} {
              syntax: '<color>';
              inherits: false;
              initial-value: ${stop.color};
            }
          `).join('\n')}

          #grain-layer {
            ${gradients.map((_, index) => `
              transition: --color-${index} ${speed}s ease-in-out;
            `).join('\n')}
          }

          .grain-backdrop-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            overflow: hidden;
          }

          .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter:  brightness(105%);
            transform: scale(1); /* subtle scale-up to compensate for blur edge */
          }

          .grain-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: ${gradientStyle};
            pointer-events: none;
          }
        `}
      </style>

      {/* 📷 Blurred full-screen background image */}
      <img src={treeBackground} alt="Background" className="background-image" />

      {/* 🌫️ Grain overlay with gradient */}
      {/* <div id="grain-layer" className="grain-overlay opacity-100 brightness-50"></div> */}
    </div>
  );
};

export default GrainBackdrop;
