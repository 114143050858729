import React, { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const SendSizeformData = () => {
  useEffect(() => {
    FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => {
        const userFingerprint = result.visitorId;
        console.log("Generated Fingerprint ID:", userFingerprint);

        // Get the target URL from the query string
        const urlParams = new URLSearchParams(window.location.search);
        const targetUrl = urlParams.get("targetUrl");

        // Fetch ALL sizeforms associated with this fingerprint
        fetch(`/api/get-sizeform-data?fingerprint=${userFingerprint}`)
          .then(res => res.json())
          .then(data => {
            console.log("Fetched Sizeform Data:", data);

            // ✅ Handle iframe postMessage
            if (window.self !== window.top) {
              console.log("Sending Sizeform data via iframe postMessage...");
              window.parent.postMessage(data, "*");
              return;
            }

            // ✅ Handle popup postMessage
            if (window.opener) {
              console.log("Sending Sizeform data via popup postMessage...");
              window.opener.postMessage(data, "*");
              setTimeout(() => window.close(), 200); // Close popup after sending
              return;
            }

            // ✅ Handle redirect-based transfer
            if (targetUrl) {
              console.log("Redirecting with Sizeform data...");
              const encodedData = encodeURIComponent(JSON.stringify(data.sizeforms));
              window.location.href = `${targetUrl}?data=${encodedData}`;
              return;
            }

            console.warn("No target URL specified, and no valid method found.");
          })
          .catch(error => {
            console.error("Error fetching Sizeform data:", error);
          });
      })
      .catch(error => {
        console.error("Error generating fingerprint:", error);
      });
  }, []);

  return <div>Processing...</div>;
};

export default SendSizeformData;
