import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from './UserManage'; // ✅ Importing the hook
import GrainBackdrop from "./grain/grain_backdrop";
import ShareButton from "./ShareButton";

const People = () => {
  const navigate = useNavigate();
  const { fingerprint, scanList, loading, error } = useUser(); // ✅ Using the context-provided data
  const [activeIndex, setActiveIndex] = useState(null);
  const scrollContainerRef = useRef(null);
  const nameRefs = useRef([]);
  const [ownerUuid, setOwnerUuid] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [ownerName, setOwnerName] = useState("");

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const containerCenter = container.scrollTop + container.clientHeight / 2;
    let closest = null;
    let closestDistance = Infinity;

    nameRefs.current.forEach((el, idx) => {
      if (el) {
        const elCenter = el.offsetTop + el.offsetHeight / 2;
        const distance = Math.abs(elCenter - containerCenter);
        if (distance < closestDistance) {
          closest = idx;
          closestDistance = distance;
        }
      }
    });

    setActiveIndex(closest);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      const mouseY = event.clientY;

      let closestIndex = null;
      let closestDistance = Infinity;

      nameRefs.current.forEach((el, index) => {
        if (el) {
          const rect = el.getBoundingClientRect();
          const elCenter = rect.top + rect.height / 2;
          const distance = Math.abs(mouseY - elCenter);

          if (distance < closestDistance) {
            closestIndex = index;
            closestDistance = distance;
          }
        }
      });

      setActiveIndex(closestIndex);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (scanList) {
      const ownedSizeform = Object.entries(scanList).find(([key, value]) => value.ownership === true);

      if (ownedSizeform) {
        const [ownedUUID, ownedData] = ownedSizeform;
        setOwnerUuid(ownedUUID);
        setOwnerName(ownedData.name || "Unnamed Sizeform");
      }
    }
  }, [scanList]);

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scanList]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading sizeforms. Please try again.</div>;

  return (
    <div className="w-full relative overflow-hidden" style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
      <GrainBackdrop />

      <div className="fixed top-0 left-0 w-full z-20 bg-transparent pointer-events-none">
        <div className="text-2xl text-left text-white/50 font-helixa-black pt-5 pb-5 pl-7">
          sizeform
        </div>
      </div>

      {ownerUuid && (
        <ShareButton fingerprint={fingerprint} uuid={ownerUuid} newName={ownerName} />
      )}

      <div ref={scrollContainerRef}
        className="w-full h-full z-10 overflow-y-auto pt-[60px] pb-[20px] fade-mask"
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        <div className="px-7">
          <div className="relative mx-auto h-[30vh] mb-[50px] max-w-[400px] text-5xl font-helixa-regular text-white/60">
            <div className="absolute bottom-0 font-helixa-regular">
              Shop for your people.
            </div>
          </div>
          <div className="mx-auto max-w-[400px] w-full mt-4">
            {Object.entries(scanList).map(([key, value], index) => (
              <div
                key={key}
                ref={(el) => (nameRefs.current[index] = el)}
                className={`relative cursor-pointer transition py-2 ${activeIndex === index ? 'text-white font-helixa-black' : 'text-white/30 font-helixa-black'} text-4xl`}
                onClick={() => navigate(`/${key}`)}
              >
                {value.ownership ? `${value.name} (You)` : (value.name || "Unnamed Sizeform")}
              </div>
            ))}
            <div className="w-0 h-[50vh]"></div>
          </div>
        </div>
      </div>

      <style>
        {`
          .fade-mask {
            mask-image: linear-gradient(to bottom, transparent, black 20%, black 90%, transparent 100%);
            -webkit-mask-image: linear-gradient(to bottom, transparent, black 20%, black 90%, transparent 100%);
          }
        `}
      </style>
    </div>
  );
};

export default People;
